import { firebase } from '@/config/firebase';
import { LocationUtil } from '@/utils/locationUtil';

export const ImageUtil = {

	getFileExtension: function (file) {
		if (file) {
			return file.name.split('.').pop();
		}
		return '';
	},

	validateFileExtension: function (file) {
		let fileExt = this.getFileExtension(file).toLowerCase();
		return fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png';
	},

	validateFileSize: function (file) {
		// this file size is in MB
		let fileSize = file.size / 1024 / 1024 / 1024;
		// 'File size exceeds 100 KB', 'File Size Exceeded'
		return fileSize <= 0.1;
	},

	uploadOnServer(file, folderName, name) {
		let filename = name + '.' + this.getFileExtension(file);
		let storageRef = firebase.storage().ref(folderName + '/' + filename);
		return storageRef.put(file).then(() => {
			return storageRef.getDownloadURL();
		}).then(downloadURL => {
			return [filename, downloadURL];
		}).catch((error) => {
			return {
				error: error
			};
		})
	},

	deleteOnServer(folderName, filename) {
		// Create a reference to the file to delete
		let storageRef = firebase.storage().ref(folderName + '/' + filename);
		return storageRef.delete().then(() => {
			// do nothing
		}).catch((error) => {
			return error;
		});
	},

	createMedia(media) {
		return {
			name: media.name,
			url: media.url,
			geoaddress: LocationUtil.getGeoaddress(media.geoaddress)
		}
	},
}
